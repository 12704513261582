
      @import "~@ecg-marktplaats/marktplaats-design-sdk/lib/themes/mp/Variables.scss";

      @import '~@hz-design-system/design-tokens/dist/web/mp/tokens/Theme.scss';
      @import '~@hz-design-system/design-tokens/dist/web/global/ThemeGetters.scss';
    
@import '~@hz-design-system/design-tokens/dist/web/global/tokens/ZIndexes';

.tabs {
  background: hz-color(background-light);
  border-bottom: hz-border(standard) hz-color(border-light);
  z-index: hz-zIndex(header) - 1;
}
