
      @import "~@ecg-marktplaats/marktplaats-design-sdk/lib/themes/mp/Variables.scss";

      @import '~@hz-design-system/design-tokens/dist/web/mp/tokens/Theme.scss';
      @import '~@hz-design-system/design-tokens/dist/web/global/ThemeGetters.scss';
    
.iframe {
  width: 100%;
  height: 75vh;
  max-height: 600px;
  border: 0;
  overflow-x: hidden;
  overflow-y: auto;
}
