
      @import "~@ecg-marktplaats/marktplaats-design-sdk/lib/themes/mp/Variables.scss";

      @import '~@hz-design-system/design-tokens/dist/web/mp/tokens/Theme.scss';
      @import '~@hz-design-system/design-tokens/dist/web/global/ThemeGetters.scss';
    
@import '~@hz-design-system/web-ui/dist/mp/apps/CategoryMenuBar/Index.css';

/* stylelint-disable */
.hz-CategoryMenuBar-Item a[href='/cp/speelgoed/'] {
  background: hz-color(background-brand-light);

  &:hover {
    background: hz-color(signal-warning-default--inverse);
  }

  .hz-SvgIcon {
    filter: hz-filter(brand-primary-dark);
  }
}
/* stylelint-enable */
