
      @import "~@ecg-marktplaats/marktplaats-design-sdk/lib/themes/mp/Variables.scss";

      @import '~@hz-design-system/design-tokens/dist/web/mp/tokens/Theme.scss';
      @import '~@hz-design-system/design-tokens/dist/web/global/ThemeGetters.scss';
    
@import '~@hz-design-system/design-tokens/dist/web/global/tokens/Breakpoints';

.container {
  @include hz-breakpoint-up(m) {
    padding: hz-spacing(xs) 0;
    position: relative;
  }
}

.list {
  @include hz-breakpoint-up(m) {
    list-style: none;
    margin: 0;
    max-height: 3000px;
    overflow-y: hidden;
    padding: 0;
  }
}

.listItem {
  @include hz-breakpoint-up(m) {
    padding-bottom: hz-spacing(xxs);

    &:last-child {
      padding-bottom: 0;
    }

    &:hover {
      background-color: transparent;
    }

    :global(.hz-Link--navigation) {
      display: inline-block;
      padding: 0;
    }

    &:hover {
      background: transparent;
    }

    &:last-child {
      border: 0;
    }

    :global(.hz-Link--navigation) {
      display: block;
      padding: 0;
    }
  }
}

.title {
  @include hz-breakpoint-up(m) {
    display: inline-block;
    margin: 0 0 hz-spacing(xs);
    text-transform: capitalize;
  }
}
