
      @import "~@ecg-marktplaats/marktplaats-design-sdk/lib/themes/mp/Variables.scss";

      @import '~@hz-design-system/design-tokens/dist/web/mp/tokens/Theme.scss';
      @import '~@hz-design-system/design-tokens/dist/web/global/ThemeGetters.scss';
    
.title {
  @include hz-textStyle(body-regular-strong);
  color: hz-color(text-primary);
  margin-bottom: hz-spacing(xs);
  display: block;
  text-decoration: none;
}

.list {
  display: flex;
  flex-wrap: wrap;
  margin: -#{hz-spacing(xxs) - hz-spacing(xxxxs)};
}

.item {
  display: block;
  margin: #{hz-spacing(xxs) - hz-spacing(xxxxs)};
}
