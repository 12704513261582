
      @import "~@ecg-marktplaats/marktplaats-design-sdk/lib/themes/mp/Variables.scss";

      @import '~@hz-design-system/design-tokens/dist/web/mp/tokens/Theme.scss';
      @import '~@hz-design-system/design-tokens/dist/web/global/ThemeGetters.scss';
    
@import '~@hz-design-system/design-tokens/dist/web/global/tokens/Breakpoints';

// Global styles
html,
body {
  @include hz-textStyle(body-regular);
  color: hz-color(text-primary);
}

.hz-Page {
  background: hz-color(background-light);

  &-container {
    width: 100%;
  }

  &-content {
    padding: hz-spacing(m) 0;
  }
}

// extra wide layout with bigger images in feeds
@include hz-breakpoint-up(xl) {
  .hz-Page-content {
    max-width: 1480px;
    padding: hz-spacing(m) hz-spacing(xs);

    .hz-Page-element {
      &--aside {
        max-width: 208px;
      }

      &--main {
        flex: 1 0 100px;
        max-width: inherit;
      }
    }
  }

  .hz-Listing.hz-Listing--vertical {
    .hz-Listing-image,
    .hz-Listing-noImage {
      // to make banner height and item height equal to 250px
      height: 190px;
    }
  }
}

.homepage-root {
  min-height: 100%;
}

.hz-CategoryMenuBarItem-link .hz-Badge {
  display: inline;
}
